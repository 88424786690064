<template>
  <span>
    <span v-b-toggle.organisation-add-invite>
      <slot></slot>
    </span>
    <b-sidebar
      id="organisation-add-invite"
      sidebar-class="small_sidebar"
      v-model="show"
      right
      backdrop
      lazy
      @shown="showSidebar"
      no-slide
      shadow
    >
      <b-overlay :show="loading" rounded="sm">
        <div class="px-3 py-2 create-message">
          <h3>{{ $t("ORGANISATION.ADD_USER") }}</h3>

          <div class="pt-2">
            <b-form-group label-cols-md="4" :label="$t('EMAIL')">
              <b-form-input trim
                required
                type="text"
                v-model="payload.email"
                name="email"
              ></b-form-input>
            </b-form-group>

            <b-form-group label-cols-md="4" :label="$t('NAME')">
              <b-form-input trim
                required
                type="text"
                v-model="payload.name"
                name="name"
              ></b-form-input>
            </b-form-group>

            <b-form-group label-cols-md="4" :label="$t('ADMINLEVEL.TITLE')">
              <b-form-select
                v-model="payload.is_admin"
                :options="adminOptions"
              ></b-form-select>
            </b-form-group>

            <b-form-group label-cols-md="4" :label="$t('LANGUAGE.TITLE')">
              <b-form-select t name="add_invite_select_language" v-model="payload.language">
                <b-form-select-option
                  v-for="(value, key) in sefos_locales"
                  :key="key"
                  :value="key"
                >
                  {{ $t("LANGUAGE." + key) }}
                </b-form-select-option>
              </b-form-select>
            </b-form-group>

            <b-form-group
              label-cols-md="4"
              :label="$t('AUTH_METHODS.SELECT_METHOD')"
            >
              <b-form-select class="mb-2"
                @input="changedAuth"
                v-model="selectedIndex"
                :options="options"
              ></b-form-select>

              <Pnr
                @valid="setValidated"
                required
                v-model="payload.data.pnr"
                v-if="payload.auth_type == 'se-eid'"></Pnr>    

              <Phone
                @valid="setValidated"
                required
                v-model="payload.data.identifier"
                v-if="payload.auth_type == 'password-sms'"></Phone>     
              
              <Identifier
              v-if="showIdentifier"
                :placeholder="$t('UNIQUE_IDENTIFIER')"
                v-model="payload.data.identifier"
              ></Identifier>              

            </b-form-group>

            <hr class="p-0 m-0 mt-2 mb-2" />

            <b-button
              :disabled="!canCreateInvite"
              variant="primary"
              class="btn-fill"
              @click="addInvite"
              >{{ $t("SUBMIT") }}</b-button
            >
          </div>
        </div>
      </b-overlay>
    </b-sidebar>
  </span>
</template>
<script>
import Pnr from "@/components/Input/Pnr.vue";
import Phone from "@/components/Input/Phone.vue";
import Identifier from "@/components/Input/Identifier.vue";
export default {
  components: {  Pnr, Identifier, Phone },
  props: ["organisation"],
  data() {
    return {
      validated: true,
      loading: false,
      show: false,
      selectedIndex: 0,
      selectedChildIndex: 0,
      childs: [],
      childs_options: [],
      methods: {},
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      options: [],
      payload: {
        email: "",
        name: "",
        data:  {
          identifier: "",
          password1: '',
          password2: '',
          pnr: ''
        },
        language: this.$i18n.locale,
        is_admin: 0,
        auth_type: "password",
        auth_method: "",
        organisation_id: this.organisation.id,
      }
    };
  },
  methods: {
    showSidebar(){
      this.getMethods();
    },
    changedAuth() {
      this.payload.data = {
        identifier: "",
        password1: '',
        password2: '',
        pnr: ''
      };
    },
    clearPayload() {
      this.payload.name = "";
      this.payload.email = "";
      this.payload.language = this.$i18n.locale;
      this.payload.is_admin = 0;
      this.payload.auth_type = "";
      this.payload.auth_method = "";
      this.payload.data = {};
      this.loading = false;
      this.selectedIndex = 0;
      this.selectedChildIndex = 0;
    },
    setValidated(value) {
      this.validated = value;
    },
    addInvite: function() {
      let self = this;
      this.loading = true;
      this.$http
        .post(
          this.user.hostname + "/invite/add",
          this.payload
        )
        .then(function() {
          self.clearPayload();
          self.$emit("invitedResult");
          self.show = false;
          self.$noty.info(self.$t("SAVED"));
        })
        .catch(function() {
          self.loading = false;
        });
    },
    async processMethods() {
      this.options = [];
      for (let ix = 0; ix < this.methods.length; ix++) {
        let method = this.methods[ix];
        let add = true;
        if((method.admin == 1) && (this.user.information.organisation.is_admin == 0))
        {
          add = false;
        } 
        let optionName = "";
        if(method.custom_name != "")
        {
          optionName = method.custom_name;
        }else{
          optionName = this.$t("AUTH_METHODS." + method.auth_type);
        }
        if(add) { 
          if(ix == 0)
          {
            this.payload.auth_type = method.auth_type;
          }                   
          this.options.push({
            value: ix,
            text: optionName,
          });
        }
      }      
      if(this.selectedIndex == 0)
      {
        this.calculateMethods(0);
      }
    },
    async getMethods() {
      let self = this;
      self.methods = [];
      await this.$http
        .get(this.user.hostname + "/invite/methods/list")
        .then(function(result) {
          self.methods = result.data;
          self.processMethods();
        })
        .catch(function() {
          self.loading = false;
        });
    },
    calculateMethods(newVal)
    { 
      if( (this.auth_type != "password") &&  this.showIdentifier == false){
        this.validated = true;
      }
      this.childs = [];
      this.childs_options = [];
      let method = this.methods[newVal];
      this.payload.auth_type = method.auth_type;
      if (method.auth_type == "custom") {
        this.payload.auth_method = method.auth_method;
      } else {
        if (method.childs.length == 0) {
          this.payload.auth_method = method.auth_method;
        } else {
          if (method.childs.length > 1) {
            this.childs_options.push({
              value: null,
              text: this.$t("ALL"),
            });
            this.selectedChildIndex = null;
          }
          for (let ax = 0; ax < method.childs.length; ax++) {
            let childmethod = method.childs[ax];
            this.childs.push(childmethod);
            this.childs_options.push({
              value: ax,
              text: this.$t("AUTH_METHODS." + childmethod.auth_method),
            });
            /*if (childmethod.auth_method == this.payload.auth_method) {
              this.selectedChildIndex = ax;
              found = true;
            }*/
          }
          this.selectedChildIndex = 0;
        }
      }
    }
  },
  computed: {
    adminOptions: function() {
      if (this.user.information.is_admin == 2) {
        return [
          { value: 0, text: this.$t("ADMINLEVEL.0") },
          { value: 1, text: this.$t("ADMINLEVEL.1") },
          { value: 2, text: this.$t("ADMINLEVEL.2") },
        ];
      } else {
        return [
          { value: 0, text: this.$t("ADMINLEVEL.0") },
          { value: 1, text: this.$t("ADMINLEVEL.1") },
        ];
      }
    },
    canCreateInvite: function() {
      if(this.validated == false)
      {
        return false;
      }
      if(this.payload.auth_type == "password-sms"){
        if(this.payload.auth_identifier == ""){ return false; }
      }
      if(this.payload.auth_type == "se-eid"){
        if(this.payload.auth_identifier == ""){ return false; }
      }
      if (this.payload.email == "") {
        return false;
      } else {
        if(this.payload.name == ""){ return false; }
        return this.validateEmail(this.payload.email);
      }
    },
    showIdentifier() {
      if(this.payload.auth_type == "custom")
      {
        return this.methods[this.selectedIndex].needs_identifier == 1;
      }
      return this.payload.auth_type == "freja-org";
    }
  },
  watch: {
    selectedIndex: function(newVal) {
      this.calculateMethods(newVal);
    },
    selectedChildIndex: function(newVal) {
      if(newVal == null){
        this.payload.auth_method = "";
      }else{
        this.payload.auth_method = this.childs[newVal].auth_method;
      }
    }
  },
  mounted() {
  },
};
</script>
<style></style>
